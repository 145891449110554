<template>
  <div :class="$style.container">
    <div :class="$style.header">
      <p :class="$style.title">Редактировать профиль</p>
      <div :class="$style.action">
        <el-button type="primary" @click="submitForm('editProfileForm')">
          Сохранить
        </el-button>
      </div>
    </div>
    <el-form
      :class="[
        $style.form,
        {
          [$style.owner]: isOwner,
        },
      ]"
      :model="form"
      :rules="rules"
      validate-on-rule-change
      ref="editProfileForm"
      label-position="top"
      label-width="12.5rem"
    >
      <div :class="$style.about">
        <el-upload
          action=""
          :auto-upload="false"
          :show-file-list="false"
          :on-change="upload"
          :class="$style.uploader"
        >
          <div :class="$style.photo" v-if="form.image">
            <img :src="$configData.s3_link + form.image" alt="Загрузить фото" />
          </div>
          <div :class="$style.photo" v-else>
            <img
              src="/img/createManagers/default-avatar.webp"
              alt="Загрузить фото"
            />
          </div>
        </el-upload>
        <el-form-item label="Имя" prop="name">
          <el-input
            v-model="form.name"
            type="text"
            placeholder="Имя"
            data-test-id="nameInput"
          ></el-input>
        </el-form-item>
        <el-form-item label="Email" prop="email">
          <el-input v-model="form.email" placeholder="Email"></el-input>
        </el-form-item>
        <el-form-item label="Телефон" prop="phone">
          <el-input v-model="form.phone" placeholder="Телефон"></el-input>
        </el-form-item>
        <el-form-item label="Мой склад id" prop="moySkladId">
          <el-input
            v-model="form.moySkladId"
            placeholder="Мой склад id"
          ></el-input>
        </el-form-item>
      </div>
      <div :class="$style.password">
        <el-form-item label="Старый пароль" prop="password">
          <el-input
            v-model="form.password"
            :disabled="isOwner"
            type="password"
            placeholder="Старый пароль"
          ></el-input>
        </el-form-item>
        <el-form-item label="Новый пароль" prop="newPassword">
          <el-input
            v-model="form.newPassword"
            type="password"
            placeholder="Новый пароль"
          ></el-input>
        </el-form-item>
        <el-form-item label="Повторите новый пароль" prop="repeatPassword">
          <el-input
            v-model="form.repeatPassword"
            type="password"
            placeholder="Повторите новый пароль"
          ></el-input>
        </el-form-item>
        <el-form-item label="Ссылка на чат с Менеджером" prop="messengerLink">
          <el-input
            v-model="form.messengerLink"
            placeholder="Ссылка на чат с Менеджером"
          ></el-input>
        </el-form-item>
        <el-form-item label="Доп. номер телефона" prop="additionalPhone">
          <el-input
            v-model="form.additionalPhone"
            placeholder="Введите номер"
          ></el-input>
        </el-form-item>
        <div :class="$style.divider"></div>
        <el-form-item label="Manager tag" prop="managerTag">
          <el-input
            v-model="form.managerTag"
            placeholder="Manager tag"
          ></el-input>
        </el-form-item>
        <el-form-item label="Роль" prop="role">
          <el-select
            v-model="form.role"
            default-first-option
            :disabled="!isOwner"
            placeholder="Выберите роль"
          >
            <el-option
              v-for="item in Object.keys($options.ROLES)"
              :key="item"
              :label="$options.TRANSLATED_ROLES[item]"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Дополнительная роль" prop="additionalRoles">
          <el-select
            v-model="form.additionalRoles"
            multiple
            :disabled="!isOwner"
            placeholder="Выберите доп. роль"
          >
            <el-option
              v-for="item in Object.keys($options.ADDITIONAL_ROLES)"
              :key="item"
              :label="$options.TRANSLATED_ADDITIONAL_ROLES[item]"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div v-if="form.managerTag.length">
          <el-form-item label="Тег включён" :class="$style.checkbox">
            <el-checkbox v-model="form.managerTagEnabled"></el-checkbox>
          </el-form-item>
        </div>
      </div>
      <div v-if="isOwner" :class="$style.info">
        <el-form-item label="Доступ к AddLandings" :class="$style.checkbox">
          <el-checkbox v-model="form.isLandings"></el-checkbox>
        </el-form-item>
        <el-form-item label="Доступ к AddSellers" :class="$style.checkbox">
          <el-checkbox v-model="form.isSellers"></el-checkbox>
        </el-form-item>
        <el-form-item label="Доступен при регистрации" :class="$style.checkbox">
          <el-checkbox v-model="form.availableUponRegistration"></el-checkbox>
        </el-form-item>
        <el-form-item label="Доступ к Addwine" :class="$style.checkbox">
          <el-checkbox v-model="form.isAddwine"></el-checkbox>
        </el-form-item>
        <el-form-item label="Доступ к AddEvent" :class="$style.checkbox">
          <el-checkbox v-model="form.isAddEvent"></el-checkbox>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import regexp from '@/helpers/regexp.js'
import delivery from '@/delivery'
import { mapGetters, mapState } from 'vuex'
import { PROJECTS } from '@/constants/projects'
import {
  ROLES,
  TRANSLATED_ROLES,
  ADDITIONAL_ROLES,
  TRANSLATED_ADDITIONAL_ROLES,
} from '@/constants/roles'
import notifications from '@/mixins/notifications'

export default {
  PROJECTS,
  ROLES,
  TRANSLATED_ROLES,
  ADDITIONAL_ROLES,
  TRANSLATED_ADDITIONAL_ROLES,
  mixins: [notifications],
  async created() {
    await this.getProfile()
  },
  computed: {
    ...mapGetters({
      isOwner: 'isOwner',
    }),
    ...mapState({
      managerId: (state) => state.auth.manager.id,
    }),
  },
  props: {
    isProfile: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      form: {
        image: '',
        name: '',
        email: '',
        phone: '',
        password: '',
        newPassword: '',
        repeatPassword: '',
        messengerLink: '',
        additionalPhone: '',
        managerTag: '',
        managerTagEnabled: false,
        availableUponRegistration: false,
        moySkladId: '',
        isLandings: false,
        isSellers: true,
        isAddwine: false,
        isAddEvent: false,
        role: null,
        additionalRoles: [],
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.email,
            message: 'Введен недопустимый символ',
            trigger: 'change',
          },
        ],
        phone: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.phone,
            message: 'Введен недопустимый символ',
            trigger: 'change',
          },
        ],
        moySkladId: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        managerTag: [
          {
            required: false,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.managerTag,
            message: 'Введен недопустимый символ',
            trigger: 'change',
          },
        ],
      },
    }
  },
  methods: {
    async submitForm(formName) {
      if (this.isProfile && !this.managerId) return

      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (
            this.form?.newPassword?.length &&
            !this.form?.password &&
            !this.isOwner
          ) {
            this.showNotification(
              'Для смены пароля, введите старый пароль',
              'error',
            )
            return
          }
          if (
            this.form?.newPassword?.length &&
            this.form?.newPassword !== this.form?.repeatPassword
          ) {
            this.showNotification('Пароли не совпадают', 'error')
            return
          }
          const loading = this.$loading({
            lock: true,
          })
          if (!this.form.managerTag.length) {
            this.form.managerTagEnabled = false
          }

          const data = {
            ...this.form,
            image: this.form.image,
            projectPermission: this.projectPermission(),
            moySkladId: this.form.moySkladId,
          }
          if (this.form.newPassword) {
            data.password = this.form.password
            data.newPassword = this.form.newPassword
          }
          const result = await delivery.AddwineCore.ManagersActions.update(
            this.isProfile ? this.managerId : this.$route.params.id,
            data,
          )

          loading.close()
          if (result.error) {
            if (result.error === 'Неверный старый пароль') {
              this.showNotification('Неверный старый пароль', 'error')
              return
            }
            this.showNotification(result.error, 'error')
            return
          }
          this.showNotification('Профиль успешно изменен', 'success')
        }
      })
    },
    async getProfile() {
      if (!this.managerId) return
      const loading = this.$loading({
        lock: true,
      })
      const result = await delivery.AddwineCore.ManagersActions.getById(
        this.isProfile ? this.managerId : this.$route.params.id,
      )

      loading.close()
      if (result.error) return
      this.form = {
        ...result.value,
        isLandings: result.value.projectPermission.includes(
          PROJECTS.ADDLANDINGS,
        ),
        isSellers: result.value.projectPermission.includes(PROJECTS.ADDSELLERS),
        isAddwine: result.value.projectPermission.includes(PROJECTS.ADDWINE),
        isAddEvent: result.value.projectPermission.includes(PROJECTS.ADDEVENTS),
      }
    },
    async upload(file) {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.StorageServiceCore.FilesActions.upload(file.raw)
      loading.close()
      if (error) {
        return
      }

      this.form.image = value
    },
    projectPermission() {
      let permission = []
      if (this.form.isLandings) permission.push(PROJECTS.ADDLANDINGS)
      if (this.form.isSellers) permission.push(PROJECTS.ADDSELLERS)
      if (this.form.isAddwine) permission.push(PROJECTS.ADDWINE)
      if (this.form.isAddEvent) permission.push(PROJECTS.ADDEVENTS)
      return permission.join(',')
    },
  },
}
</script>

<style lang="scss" module>
.container {
  background: $white;
  min-height: 100vh;
  line-height: normal;
  .header {
    @include baseShadow;
    position: sticky;
    top: 0;
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    z-index: $z-index-header;
    .title {
      @include title;
    }
  }
  .form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    padding: 1rem 2rem;
    &.owner {
      grid-template-columns: repeat(3, 1fr);
    }

    @include custom(992) {
      &,
      &.owner {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    @include tablet {
      &,
      &.owner {
        grid-template-columns: 1fr;
      }
    }
    label {
      line-height: normal;
    }
    .about {
      .photo {
        width: 15rem;
        margin: 1rem auto 1rem 0;
        height: 15rem;
        border-radius: 50%;
        @include baseShadow;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
    .password {
      .title {
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
