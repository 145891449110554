<template>
  <div>
    <Header :breadcrumb="$options.BREADCRUMBS.PROFILE" />
    <ManagerEditForm />
  </div>
</template>

<script>
import ManagerEditForm from '@/components/organisms/ManagerEditForm.vue'
import Header from '@/components/atoms/Header.vue'
import { BREADCRUMBS } from '@/constants/breadcrumb'
export default {
  BREADCRUMBS,
  components: {
    Header,
    ManagerEditForm
  }
}
</script>
